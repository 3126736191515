import { ConfigService } from '@packages/core/config';
import { StorageService } from '@packages/core/storage';
import { AnalyticsService } from '@packages/core/analytics';
import { AuthService, AuthTokenService } from '@packages/contexts/auth';
import { QmrsService, FailCodesService } from '@packages/contexts/qmrs';

import { getEnvConfig } from '@web/config';

import { HttpClient } from './http-client';
import { AssetUploadsService } from './asset-uploads.service';
import { PushNotificationsService } from './push-notifications.service';
import { NotificationsService } from '@packages/contexts/notifications';
import { CommentsService } from '@packages/contexts/comments';
import { FeedbackService, FeedbackAdminService } from '@packages/contexts/feedback';
import { EntitiesService } from '@packages/contexts/entities';
import { AccountsService } from '@packages/contexts/accounts';
import { LocationsService } from '@packages/contexts/locations';
import { TechlineService } from '@packages/contexts/techline/techline.service';
import { DashboardService } from '@packages/contexts/dashboard/dashboard.service';
import { ToughbookAttachmentsService } from '@packages/contexts/toughbook-attachments';
import { InvestigationService } from '@packages/contexts/investigations';
import { EscalationService } from '@packages/contexts/escalations';
import { WorksheetsService } from '@packages/contexts/worksheets';
import {
    QmrsDashboardDistrictStatsService,
    QmrsDashboardService,
    PendingReviewQmrsService,
} from '@packages/contexts/qmrsdashboard';
import { VinSessionService } from '@web/vin-sessions/context/vin-session.service';

const storageService = new StorageService(window.localStorage);
const configService = new ConfigService(storageService, getEnvConfig());
const authTokenService = new AuthTokenService(storageService);
const httpClient = new HttpClient(storageService, configService, authTokenService);
const analyticsService = new AnalyticsService(configService);

const authService = new AuthService(httpClient, authTokenService);
const qmrsService = new QmrsService(httpClient);
const assetUploadsService = new AssetUploadsService(httpClient);
const notificationsService = new NotificationsService(httpClient);
const commentsService = new CommentsService(httpClient);
const feedbackService = new FeedbackService(httpClient);
const feedbackAdminService = new FeedbackAdminService(httpClient);
const pushNotificationsService = new PushNotificationsService(storageService, configService, httpClient);
const entitiesService = new EntitiesService(httpClient);
const accountsService = new AccountsService(httpClient);
const failCodesService = new FailCodesService(httpClient);
const techlineService = new TechlineService(httpClient);
const dashboardService = new DashboardService(httpClient);
const locationsService = new LocationsService(httpClient);
const toughbookAttachmentsService = new ToughbookAttachmentsService(httpClient);
const investigationService = new InvestigationService(httpClient);
const escalationsService = new EscalationService(httpClient);
const worksheetsService = new WorksheetsService(httpClient);
const qmrsDashboardService = new QmrsDashboardService(httpClient);
const qmrsDashboardDistrcitStatsService = new QmrsDashboardDistrictStatsService(httpClient);
const pendingReviewQmrsService = new PendingReviewQmrsService(httpClient);
const vinSessionService = new VinSessionService(httpClient);
export {
    httpClient,
    storageService,
    configService,
    authService,
    authTokenService,
    qmrsService,
    assetUploadsService,
    pushNotificationsService,
    notificationsService,
    commentsService,
    feedbackService,
    feedbackAdminService,
    entitiesService,
    accountsService,
    failCodesService,
    techlineService,
    dashboardService,
    locationsService,
    toughbookAttachmentsService,
    analyticsService,
    investigationService,
    escalationsService,
    worksheetsService,
    qmrsDashboardService,
    qmrsDashboardDistrcitStatsService,
    pendingReviewQmrsService,
    vinSessionService,
};
